



























































































import Vue from "vue";
import { namespace } from "vuex-class";
import { User } from "@/store/users/types";

//const shopsModule = namespace("shops");
//const usersModule = namespace("users");

export default Vue.extend({
  name: "Users",

  data: function () {
    return {
      selectedUser: {} as User,
      search: "",
      showStaffSearch: false,
      userTableHeaders: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Username",
          value: "profile.email",
        },
        {
          text: "Manager",
          value: "manager",
        },
        {
          text: "Viewer",
          value: "viewer",
        },
        {
          text: "Administrator",
          value: "administrator",
        },
        {
          text: "Primary Contact",
          value: "primary",
        },
        {
          text: "Delete",
          value: "delete",
        },
      ],
      loading: this.$store.state.users.loading,
    };
  },

  mounted: function () {
    this.$store.dispatch("users/fetchUsers");
  },

  watch: {
    search: function (searchVal) {
      if (!searchVal) return;
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(searchVal).toLowerCase()))
        this.$store.dispatch("users/searchUsersByEmail", searchVal);
    },
  },

  computed: {
    allUsers: function () {
      return this.$store.state.users.users;
    },
    searchingUsers: function () {
      return this.$store.state.users.searchingUsers;
    },
    searchedUsers: function () {
      return this.$store.state.users.searchedUsers;
    },
  },

  methods: {
    onSearch(searchVal: string) {
      if (!searchVal) return;
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(String(searchVal).toLowerCase()))
        this.$store.dispatch("users/searchUsersByEmail", searchVal);
    },
    async onAddStaffClick() {
      this.showStaffSearch = true;
      await this.$nextTick();
      const emailElement = this.$refs.emailSearch as any;
      emailElement.focus();
    },
    onUserAdd() {
      this.$store.dispatch("shops/addUserToOrganisation", {
        organisationId: +this.$store.state.shops.activeShop.id,
        user: this.selectedUser,
        groups: [200],
      });
    },
    assignPrimary(user: User) {
      this.$store.dispatch("shops/addPrimaryUserToOrganisation", {
        organisationId: this.$store.state.shops.activeShop.id,
        user: user,
      });
    },
    userHasGroup(groupId: any, userGroups: any) {
      for (let group of userGroups) {
        if (group.id === groupId) return true;
      }
    },
    userIsPrimary(userId: string) {
      if (this.$store.state.shops.activeShop.profile.contactPerson)
        return (
          this.$store.state.shops.activeShop.profile.contactPerson.id ===
          userId
        );
      else return this.$store.state.shops.activeShop.profile.id == userId;
    },
    onGroupChange(val: boolean, groupId: number, user: User) {
      if (!val)
        this.$store.dispatch("shops/removeGroupFromUser", {
          organisationId: this.$store.state.shops.activeShop.id,
          groups: [groupId],
          user,
        });
      else
        this.$store.dispatch("shops/addUserToOrganisation", {
          organisationId: this.$store.state.shops.activeShop.id,
          groups: [groupId],
          user,
          successMessage: "Roles Updated",
        });
    },
    onDeleteUser(user: User, userGroups: any[]) {
      let groups = [];
      for (let group of userGroups) groups.push(group.id);

      this.$store.dispatch("shops/removeGroupFromUser", {
        organisationId: this.$store.state.shops.activeShop.id,
        groups,
        user,
      });
    },
  },
});
