var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('S2SForm',{attrs:{"title":"Staff"}},[_c('v-layout',{attrs:{"slot":"toolbar-content","wrap":"","justify-end":""},slot:"toolbar-content"},[(_vm.showStaffSearch)?_c('v-flex',{attrs:{"xs6":"","md6":""}},[_c('v-autocomplete',{ref:"emailSearch",staticClass:"mx-2",attrs:{"loading":_vm.searchingUsers,"items":_vm.searchedUsers,"search-input":_vm.search,"cache-items":"","text":"","label":"Type in valid email address","item-text":"email","return-object":"","hide-details":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1):_c('v-flex',{attrs:{"md3":""}},[_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.onAddStaffClick}},[_vm._v("Add New Staff")])],1),(_vm.selectedUser.id)?_c('v-flex',{attrs:{"xs3":"","md1":""}},[_c('v-btn',{attrs:{"color":"accent"},on:{"click":function($event){return _vm.onUserAdd()}}},[_vm._v("Add")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.userTableHeaders,"items":_vm.allUsers,"hide-default-footer":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.profile.firstName)+" "+_vm._s(item.profile.surname))])]}},{key:"item.manager",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"input-value":_vm.userHasGroup(3, item.groups),"color":"primary","hide-details":"","height":"35px"},on:{"change":function($event){return _vm.onGroupChange($event, 3, item)}}})]}},{key:"item.viewer",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"input-value":_vm.userHasGroup(200, item.groups),"color":"primary","hide-details":"","height":"35px"},on:{"change":function($event){return _vm.onGroupChange($event, 200, item)}}})]}},{key:"item.administrator",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"input-value":_vm.userHasGroup(2, item.groups),"color":"primary","hide-details":"","height":"35px"},on:{"change":function($event){return _vm.onGroupChange($event, 2, item)}}})]}},{key:"item.primary",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":_vm.userIsPrimary(item.id),"color":"primary"},on:{"click":function($event){return _vm.assignPrimary(item)}}},[_vm._v(_vm._s(_vm.userIsPrimary(item.id) ? "Primary" : "Assign"))])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":_vm.userIsPrimary(item.id),"text":""},on:{"click":function($event){return _vm.onDeleteUser(item, item.groups)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("delete")])],1)]}}])},[_c('template',{slot:"footer"},[_c('td',{attrs:{"colspan":_vm.userTableHeaders.length}})])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }